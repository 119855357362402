import "./styles.scss"

import React, { useContext } from "react"
import { useQuery } from "@apollo/client"
import { NumericFormat } from "react-number-format"
import { motion } from "framer-motion"

import Button from "components/Button"

import { AppContext } from "context/app_context"

import { getFormattedCart, isUserLoggedIn } from "utils/functions"

import GET_CART from "queries/get-cart"

import { Table } from "./components"

import fadeAnimation from "utils/fadeAnimation"

const Content = () => {
  const isLoggedIn = isUserLoggedIn()
  const [cart, setCart] = useContext(AppContext)

  // Get Cart Data
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const updatedCart = getFormattedCart(data)
      localStorage?.setItem(
        "woo-next-cart-wallcraft",
        JSON.stringify(updatedCart)
      )
      setCart(updatedCart)
    },
    onError: error => {
      console.warn(error)
    },
  })

  return (
    <motion.section className="cart-content" {...fadeAnimation}>
      <div className="container-fluid">
        {cart ? (
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <Table cart={cart} setCart={setCart} refetch={refetch} />
            </div>

            <div className="col-xl-3 col-lg-4">
              <div className="cart-content__summary">
                <h5>Podsumowanie</h5>
                <div className="cart-content__summary-item">
                  <div>
                    <strong>Wartość koszyka</strong>
                    <span>
                      {(
                        <NumericFormat
                          value={cart?.totalSubProductsPrice?.replace(/,/g, "")}
                          displayType={"text"}
                          thousandSeparator={" "}
                          decimalScale={0}
                          suffix={" zł"}
                        />
                      ) || "-"}
                    </span>
                  </div>
                </div>
                <div className="cart-content__summary-item">
                  <div>
                    <strong>Czas realizacji</strong>
                    <span>
                      {cart?.processingTime === "express"
                        ? "3 dni robocze"
                        : "7 - 10 dni roboczych"}
                    </span>
                  </div>
                  <small>
                    Czas wynikający z dostępności produktu z najdłuższym czasem
                    realizacji
                  </small>
                </div>
                <div className="cart-content__summary-item">
                  <div>
                    <strong>Do zapłaty</strong>
                    <h4>
                      {(
                        <NumericFormat
                          value={cart?.totalSubProductsPrice?.replace(/,/g, "")}
                          displayType={"text"}
                          thousandSeparator={" "}
                          decimalScale={0}
                          suffix={" zł"}
                        />
                      ) || "-"}
                    </h4>
                  </div>
                </div>

                <div className="cart-content__summary-cta">
                  <Button
                    to={isLoggedIn ? "/zamowienie/" : "/konto/"}
                    disabled={!cart?.totalProductsCount}
                  >
                    Dostawa i płatności
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="cart-content__info">
            <h5>Brak produktów w koszyku.</h5>
            <Button to="/">Strona główna</Button>
          </div>
        )}
      </div>
    </motion.section>
  )
}

export default Content
